@import "../../assets/scss/variables.scss";
.dropdownField {

  :global .select-dropdown {
    margin-bottom: 0;
  }
  &__label {
    font-size: 16px;
        color: $color-gray;
        display: block;
        margin-bottom: 10px;
  }
  &__error {
    color: $color-error;
    margin-top: 5px;
    font-size: 14px;
  }
  &.isDisable {
    opacity: 0.5;
    pointer-events: none;
  }
  :global{
    .select-dropdown__placeholder{
        color: $color-gray-fade;
    }
    .select-dropdown__control{
        border: 1px solid $color-gray-dark !important;
        border-radius: $radius;
        box-shadow: 0 0 0;
        width: 100%;
        font-size: 16px;
        padding: 10px;
        color: $color-black;
        min-height: 61px;
        @include max-screen($tablet) {
            padding: 6px 15px;
            min-height: 50px;
        }
      }
      .select-dropdown__multi-value {
        border-radius: 55px;
        background-color: $color-primary;
        color: $color-white;
        padding: 0 5px;
        display: flex;
    align-items: center;
    gap: 5px;
    }
    
    .select-dropdown__multi-value__label {
        color: $color-white;
    }
    .select-dropdown__multi-value__remove{
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-white !important;
        border-radius: 100%;
        color: $color-primary!important;
    }
  } 
}
