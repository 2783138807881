@import '../../assets/scss/variables.scss';
.createCategory {
    h2 {
        margin-bottom: 0px;
        padding-right: 10px;
    }
    &__head{
        width: 100%;
        padding-bottom: 20px;
    }
    &__content {
        padding: 25px;
        border-radius: 10px;
        background-color: $color-white;
    }
    &__upload {
        gap: 20px;
        margin-top: 30px;
    }
    &__uploadItem {
        width: calc(50% - 10px);
        @include max-screen($mobile) {
            width: 100%;
        }
    }
    &__footer {
        gap: 10px;
    }
}