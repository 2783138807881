@import "../../assets/scss/variables.scss";
.dropdownField {
position: relative;
  label {
    font-size: 16px;
        color: $color-gray;
        display: block;
        margin-bottom: 10px;
  }
  input{
    border: 1px solid $color-gray-dark;
            border-radius: $radius;
            width: 100%;
            padding: 20px;
            font-size: 16px;
            color: $color-black;
            text-overflow: ellipsis;
  white-space: nowrap;
 overflow: hidden;
            @include max-screen($tablet) {
                padding: 15px;
                font-size: 14px;
            }
            &::placeholder {
                color: $color-gray-fade;
                opacity: 1;
            }

            &::-moz-placeholder {
                color: $color-gray-fade;
                opacity: 1;
            }
            /* Chrome, Safari, Edge, Opera */
&::-webkit-outer-spin-button,
&::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
  }
  &__error {
    color: $color-error;
    margin-top: 5px;
    font-size: 14px;
  }
  &.isDisable {
    opacity: 0.5;
    pointer-events: none;
  }
  &__list {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    position: absolute;
    width: 100%;
    left: -30px;
    top: 45px;
    max-height: 260px;
    overflow-y: auto;
    z-index: 9;
    border-radius: $radius;
    left: 0;
    top: 95px;
    border: 1px solid $color-gray-dark;;
    @include max-screen($mobile) {
        top:35px;
    }
li {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    padding: 10px;
    cursor: pointer;
&:hover{
color: $color-primary;
}
    svg,
    img {
        width: 30px;
    }
}
}
&__skeleton{
width: 100%;
}
}
