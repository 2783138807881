@import "../../assets/scss/variables.scss";

.inputField {
    label{
        font-size: 16px;
        color: $color-gray;
        display: block;
        margin-bottom: 10px;
    }
    &__input {
        position: relative;
        input, textarea {
            border: 1px solid $color-gray-dark;
            border-radius: $radius;
            width: 100%;
            padding: 20px;
            font-size: 16px;
            color: $color-black;
            @include max-screen($tablet) {
                padding: 15px;
                font-size: 14px;
            }
            &::placeholder {
                color: $color-gray-fade;
                opacity: 1;
            }

            &::-moz-placeholder {
                color: $color-gray-fade;
                opacity: 1;
            }
            /* Chrome, Safari, Edge, Opera */
&::-webkit-outer-spin-button,
&::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
&[type=number] {
  -moz-appearance: textfield;
}
        }
    }
    &__prefix {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        img {
            width: 20px;
        }
    }
    &.inputFieldPassword {
        input {
            padding-right: 50px;
            
        }
    } 
    &__error {
        color: $color-error;
        margin-top: 5px;
        font-size: 14px;
    }
    &.textarea{
        textarea {
           height: 122px;
           resize: vertical;
           min-height: 80px;
            
        } 
    }
}
