@import "../../assets/scss/variables.scss";
.contact{
    &__search{
       padding: 20px;
       border-radius: $radius $radius 0 0;
       background-color: $color-white;
input{
    border: 1px solid $color-gray-dark;
    border-radius: $radius;
    height: 40px;
    width: 100%;
    max-width: 360px;
    padding: 10px 30px 10px 10px;
    background: $color-white-shade;
    @include background-image(search-icon, svg, calc(100% - 10px) center, no-repeat, 12px);
    

}
    }
}