@import '../../assets/scss/variables.scss';
.skeleton {
    width: 100%;
    overflow: hidden;
    user-select: none;
    position: relative;
    background-color: #ebebeb;
    &::after {
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        content: ' ';
        display: block;
        position: absolute;
        transform: translateX(-100%);
        background-repeat: no-repeat;
        animation: 1.5s react-loading-skeleton infinite ease-in-out;
        background-image: linear-gradient(90deg, #ebebeb, #f5f5f5, #ebebeb);
    }
}
@keyframes react-loading-skeleton {
    100% {
        transform: translateX(100%);
    }
}