@import '../../assets/scss/variables.scss';
.categories {
    &__header {
        margin-bottom: 20px;
   
        p{
            font-size: 14px;
            color: $color-gray;
        }
        span {
            display: none;
            @include max-screen($mobile) {
                height: 40px;
                display: flex;
                border-radius: 20px;
                align-items: center;
                color: $color-white;
                padding: 3px 20px;
                justify-content: center;
                background-color: $color-primary;
            }
        }
        button {
            @include max-screen($mobile) {
                display: none;
            }
        }
    }
    &__count {
        margin-bottom: 10px;
    }
}