@import '../../assets/scss/variables.scss';
.confirmation {
    text-align: center;
    h5 {
        margin-bottom: 8px;
    }
    p,
    h5 {
        word-wrap: break-word;
    }
}