@import "../../assets/scss/variables.scss";

.dataBlock {
    overflow: hidden;
    border-radius: 0 0 $radius $radius;
    background-color: $color-white;
    overflow-x: auto;

    table {
        min-width: 100%;
        width: max-content;
        border-collapse: collapse;

        tr {
            th {
                text-align: left;
                font-weight: 400;
                white-space: nowrap;
                font-size: 16px;
                padding: 10px 20px;
                background-color: $color-white-shade-2;

                &:last-child {
                    div {
                        justify-content: center;
                    }

                }
            }

            td {
                padding: 26px 20px;
                font-size: 14px;
                color: $color-gray;
            }

            &:not(:last-child) {
                td {
                    border-bottom: 1px solid $color-gray-dark;
                }

            }
        }

        &__nodata {
            text-align: center;
        }

        &__wrap {
            min-width: max-content;
            border-radius: $radius;
            border: 1px solid $color-gray-light;
        }

        &__scroll {
            overflow-x: auto;
        }


    }

    &__nodata {
        text-align: center;
    }

    &__data {
        max-width: 300px;
        width: 100%;
        word-break: break-word;
    }

    &__action {

        button,
        a {
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: 0;

            &+button,
            &+a {
                margin-left: 10px;
            }

            &:disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
}