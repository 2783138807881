@import "../../assets/scss/variables.scss";
.fileInput{
   label{
    font-size: 16px;
        color: $color-gray;
        display: block;
        margin-bottom: 10px;
   }
    &__inner{
        width: 100%;
        height: 300px;
        border: 1px dashed $color-gray-dark;
        border-radius: 20px;
        background-color: $color-white;
        padding: 15px;
        @include max-screen($tablet) {
            height: 200px;
        }
    }
    &__info{
        max-width: 425px;
        margin: 0 auto;
        text-align: center;
        figure{
            display: inline-flex;
            margin: 0 auto 15px;
        }
        p{
            color: $color-gray-fade;
            font-size: 14px;
        }
    }
    &__error{
        color: $color-error;
        margin-top: 5px;
        font-size: 14px;
    }
    &__img{
        width: 100%;
        margin-top: 20px;
        gap: 25px;
        @include max-screen($tablet) {
            justify-content: center;
         }
        figure{
            position: relative;
            display: table;
            background-color: $color-gray-light;
            margin-top: 15px;
            padding: 5px;
            border-radius: $radius;
            height: 220px;
            width: 220px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: $radius;
           
        }
        span{
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 2;
            background-color: $color-white;
            border: 1px solid $color-gray;
            padding: 5px;
            @include round-image(30px)
        }
    }
}