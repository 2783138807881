@import '../../assets/scss/variables.scss';
.tagInput {
    h4 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 24px;
    }
    label{
        font-size: 16px;
        color: $color-gray;
        display: block;
        margin-bottom: 10px;
    }
    &__field {
        border: 1px solid $color-gray-dark;
        border-radius: $radius;
        width: 100%;
        padding: 15px;
        font-size: 16px;
        color: $color-black;
        // @include background-image(add-plus, svg, calc(100% - 15px) center , no-repeat, 15px);

        @include max-screen($tablet) {
            padding: 9.5px 15px;
            font-size: 14px;
        }
      
        input {
            border: 0;
            flex: auto;
            outline: none;
            padding: 5px 4px;
            font-size: 16px;
            appearance: none;
            font-weight: 500;
            min-width: 100px;   
            width: 100%;      
            background-color: transparent;
            &::placeholder {
                color: $color-gray-fade;
                opacity: 1;
            }
    
            &::-moz-placeholder {
                color: $color-gray-fade;
                opacity: 1;
            }
        }
    }
    &__button {
        margin: 2px;
        line-height: 1;
        min-height: 28px;
        font-size: 14px;
        border-radius: 20px;
        align-items: center;
        color: $color-white;
        display: inline-flex;
        padding: 2px 5px 2px 12px;
        background-color: $color-primary;
        cite {
            font-style: normal;
            word-wrap: break-word;
            max-width: calc(520px - 25px);
            word-break: break-word;
            @include max-screen($mobile) {
                max-width: calc(100vw - 144px);
            }
        }
        span {
            width: 18px;
            height: 18px;
            min-width: 18px;
            display: flex;
            cursor: pointer;
            border-radius: 50%;
            margin-left: 5px;
            align-items: center;
            color: $color-primary;
            justify-content: center;
            background-color: $color-white;
            img{
                width: 12px;
            }
        }
        & + input{
            width: fit-content;
        }
    }
    &__error {
        font-size: 14px;
        margin-top: 3px;
        display: block;
        padding: 0 3px;
        color: $color-error;
    }
    &__list {
        margin-top: 28px;
    }
    &__item {
        margin: 20px 0;
    }
    &__email {
        font-size: 14px;
        font-weight: 600;
        color: $color-black;
        word-wrap: break-word;
        width: calc(100% - 120px);
    }
}