@import "../../assets/scss/variables.scss";

.dashboard {
    width: 100%;
    background-color: $color-gray-light;
    min-height: 100vh;

    &__main {
        width: 100%;
        padding-left: 300px;
        @include max-screen($tablet) {
          padding-left: 0;
          }
    }

    &__content {
        width: 100%;
        padding: 38px 50px;
        @include max-screen($tablet) {
            padding: 15px;
            }
    }
}