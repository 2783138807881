
// colors
$color-primary: #2E3192;
$color-white: #fff;
$color-black: #000;
$color-gray:#808080;
$color-error:#da1818;
$color-gray-dark:#D8DEE3;
$color-gray-light:#F1F5F8;
$color-gray-fade: #C9C9C9;
$color-white-fade: #fcfcfd;
$color-white-shade:#F8FAFC;
$color-white-shade-2:#EBEEF1;




// typography
$font-size-base: 16px;
$line-height-base: 1.5;
$font-primary:"Montserrat", sans-serif;

// utilities 
$radius: 10px;
$gradient: linear-gradient(182.79deg, #6568c5 4.64%, rgba(167,180, 177, 0.6) 50.25%, rgba(46, 49, 146, 0.8) 95.36%);
$shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.071);


// breakpoints
$small: 575px;
$mobile: 767px;
$tablet: 991px;
$desktop: 1199px;

// image path
$imgPath: "../../assets/images/";

// mixin
@import 'mixins';