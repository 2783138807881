@import "../../assets/scss/variables.scss";

.member{
    &__header{
        gap: 10px;
    }
    &__actions{
        gap: 10px;
    }
    &__form{
        background-color: $color-white;
        margin: 20px 0 50px 0;
        padding: 30px;
        border-radius: $radius;
        @include max-screen($tablet) {
            padding: 15px;
            margin: 20px 0 ;
        }
        h2{
            margin-bottom: 20px;
        }
    }
    &__buttons{

        span{
            @include round-image(35px);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-error;
        }
    }
    &__formHeader{
        gap: 15px;
    margin-bottom: 15px;
    h2{
        margin: 0;
    }
    }
    &__addFieldIcon{
        .member__row{
            width: calc(100% - 60px);
            
        }
    }
    &__addField{
display: flex;
align-items: center;
width: 100%;
gap: 30px;
margin-bottom: 30px;
.member__row{
    width: 100%;
}
    }
    &__address{
        label{
            font-size: 16px;
            color: $color-gray;
            display: block;
            margin-bottom: 10px;
        }
        input {
            border: 1px solid $color-gray-dark;
            border-radius: $radius;
            width: 100%;
            padding: 20px;
            font-size: 16px;
            color: $color-black;
            @include max-screen($tablet) {
                padding: 15px;
                font-size: 14px;
            }
            &::placeholder {
                color: $color-gray-fade;
                opacity: 1;
            }

            &::-moz-placeholder {
                color: $color-gray-fade;
                opacity: 1;
            }
        }
    }
    &__error{
        color: $color-error;
        margin-top: 5px;
        font-size: 14px;
    }
    &__row{
        display: flex;
        flex-wrap: wrap;
        gap: 20px 50px;
    }
    &__col{
        width: calc(50% - 25px);
       
        @include max-screen($tablet) {
           width: 100%;
        }
    }
}