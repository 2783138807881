@import '../../assets/scss/variables.scss';
.notFound{
   display: flex;
   flex-direction: column;
   min-height: 100vh;
    &__inner{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        max-width: 620px;
        text-align: center;
        padding: 0 15px;
        h1{
            font-size: 82px;
            line-height: 1;
            color: $color-primary;
        }
    }
    &__main{
       flex: 1;
    }
    &__header{
        padding: 10px 15px;
        box-shadow: $shadow;
        span{
            img{
                width: 50px;
                height:50px;
            }
        }
       
    }
}