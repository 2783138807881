@import "../../assets/scss/variables.scss";

.logo {
    img {
        width: 74px;
        height: 74px;
        @include max-screen($tablet) {
            width: 45px;
            height: 45px;
        }
    }
    p {
        font-size: 16px;
        font-weight: 600;
        color: $color-white;
        padding-left: 10px;
        cursor: default;
        @include max-screen($tablet) {
            font-size: 14px;
        }
    }
    &.logoIcon{
        img{
            width: 116px;
            height: 116px;
            @include max-screen($tablet) {
                width: 45px;
                height: 45px;
            }
        }
    }
    &.logoBlack{
        p {
            color: $color-black;
            font-weight: 400;
            line-height: 1.2;
            font-size: 14px;
            @include max-screen($mobile) {
                font-size: 12px;
            }
            @include max-screen($small) {
                font-size: 10px;
            }
        } 
        img{
            @include max-screen($tablet) {
                width: 35px;
                height: 35px;
            }
        }
    }
}
