@import "../../assets/scss/variables.scss";
.cards{
    display: flex;
    flex-wrap: wrap;
    gap:20px;
    
   
    &__card{
        width: 100%;
        padding: 30px 15px;
        border-radius: $radius;
        background-color: $color-white-fade;
        border: 1px solid $color-gray-dark;
  
    }
}