@import "../../assets/scss/variables.scss";

.header {
    padding: 20px 50px;
    box-shadow: $shadow;
    background-color: $color-white;
    @include max-screen($tablet) {
        padding: 15px;
        justify-content: space-between;
    }
    &__menu{
        gap: 15px;
        ul {
            gap: 40px;
            @include max-screen($tablet) {
               display: none;
            }
            li{
                display: inline-flex;
                color: $color-gray;
                span{
                    display: inline-flex;
                   
                }
                &:hover {
                    color: $color-primary;
                }
            }
        }
    }
 
    &__logo{
        display: none;
        @include max-screen($tablet) {
            display: block;
        }
    }
    &__dropdown {
        position: relative;
        ul {
            display: none;
            position: absolute;
            box-shadow: $shadow;
            width: 120px;
            padding: 5px;
            background-color: $color-white;
            right:  0;
            top: 50px;
            z-index: 9;
            li {
                padding: 5px;
                font-size: 14px;
                width: 100%;
                &:hover {
                    color: $color-primary;
                }
            }
        }
        &.active {
            ul {
                display: flex;
            }
        }
    }
    &__user {
        gap: 10px;
        font-size: 14px;
        @include max-screen($tablet) {
            gap: 5px;
        }
        figure {
            width: 35px;
            height: 35px;
            @include max-screen($tablet) {
                width: 25px;
                height: 25px;
            }
            img {
                @include round-image(100%);
            }
        }
        span {
            display: flex;
            align-items: center;
            gap: 10px;
            color: $color-black;
            img {
                transition: 0.2s;
                .active & {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &__toggle{
        display: none;
        @include max-screen($tablet) {
          display: inline-flex;
        }
    }
   
}