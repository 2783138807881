@mixin min-screen($screenSize) {
    @media (min-width: ($screenSize +1)) {
        @content;
    }
}
@mixin max-screen($screenSize) {
    @media only screen and (max-width: $screenSize) {
        @content;
    }
}
@mixin background-image($img, $ext, $pos, $repeat: no-repeat, $size: auto) {
    background-image: url(#{$imgPath}#{$img}.#{$ext});
    background-size: $size;
    background-position: $pos;
    background-repeat: $repeat;
}
@mixin round-image($imgSize) {
    width: $imgSize;
    height: $imgSize;
    min-width: $imgSize;
    object-fit: cover;
    border-radius: 50%;
}


