@import "../../assets/scss/variables.scss";

.sidebar {
    &__nav {
        background-color: $color-white;
        box-shadow: $shadow;
        width: 300px;
        height: 100vh;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        padding: 30px 50px;
        overflow-y: auto;

        @include max-screen($tablet) {
            position: fixed;
            left: 0;
            top: 0;
            z-index: 6;
            transition: all .5s;
            transform: translateX(-300px);
            padding: 15px;
        }

        .active & {
            transform: translateX(0);
        }

        ul {
            margin-top: 38px;
            display: flex;
            flex-direction: column;
            gap: 40px;

            @include max-screen($tablet) {
                gap: 20px;
                margin-top: 15px;
            }

            li {

                a,
                div {
                    color: $color-gray;
                    font-size: 16px;
                    display: inline-flex;
                    align-items: center;
                    gap: 12px;
                    line-height: 1;

                    &:hover {
                        color: $color-primary;
                    }

                    svg {
                        width: 15px;
                        height: 15px;
                    }
                }

                :global .active {
                    color: $color-primary;
                }

                &.logOut {
                    display: none;

                    @include max-screen($tablet) {
                        display: block;
                    }
                }

                ul {
                    padding-left: 15px;
                    gap: 15px;
                    margin-top: 15px;
                    li {
                        a {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    &__logo {
        @include max-screen($tablet) {
            display: none;
        }
    }

    &__overlay {
        @include max-screen($tablet) {
            position: fixed;
            width: 100%;
            height: 100%;
            z-index: 5;
            top: 0;
            left: 0;
            background-color: rgba($color-black, 0.5);
            opacity: 0;
            transition: all .5s;
            pointer-events: none;
        }

        .active & {
            opacity: 1;
            pointer-events: all;
        }
    }

    &__close {
        display: none;

        @include max-screen($tablet) {
            display: flex;
        }
    }
}