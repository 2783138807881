@import "../../assets/scss/variables.scss";
.accordion{
    background-color: $color-white;
    border-radius: $radius;
    padding: 27px;
    @include max-screen($tablet) {
        padding: 15px;
    }
    &__header{
        gap: 15px;
        span{
            min-width: 17px;
        }
    }
    &__content{
        padding: 30px 0;
    }
}
