@import "../../assets/scss/variables.scss";
.pagination {
background-color: $color-gray-light;
padding-top: 30px;
  @include max-screen($mobile) {
    justify-content: center;
  }
  p {
    font-size: 14px;
    color: $color-gray;
    @include max-screen($mobile) {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
  }
  button,
  li {
    border: 0;
    height: 40px;
    display: flex;
    outline: none;
    min-width:40px;
    cursor: pointer;
    user-select: none;
    padding: 3px 5px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    font-size: 14px;
    color: $color-gray;
    &.active,
    &:hover {
      // background-color: darken($color-gray, 10%);
    }
    &.active {
      pointer-events: none;
    }
  }
  button {
    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  ul {
    margin: 0 5px;
    li {
      margin: 0 1px;
      &.isMore {
        pointer-events: none;
        cursor: default !important;
      }
      &.active,
      &:hover {
        color: $color-white;
        background-color: $color-primary;
  
      }
    }
  }
}