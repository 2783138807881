@import "../../assets/scss/variables.scss";

.inputMask {
    label{
        font-size: 16px;
        color: $color-gray;
        display: block;
        margin-bottom: 10px;
    }
    &__input {
        position: relative;
        input {
            border: 1px solid $color-gray-dark;
            border-radius: $radius;
            width: 100%;
            padding: 20px;
            font-size: 16px;
            color: $color-black;
            @include max-screen($tablet) {
                padding: 15px;
                font-size: 14px;
            }
            &::placeholder {
                color: $color-gray-fade;
                opacity: 1;
            }

            &::-moz-placeholder {
                color: $color-gray-fade;
                opacity: 1;
            }
           
        }
    }
    &__error {
        color: $color-error;
        margin-top: 5px;
        font-size: 14px;
    }
}