@import "../../assets/scss/variables.scss";

.customModal {
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    visibility: hidden;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    background-color: rgba($color-black, 0.5);
    &.active {
        opacity: 1;
        visibility: visible;
    }
    &.isScroll {
        overflow-y: auto;
    }
    &__backdrop {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }
    &__content {
        z-index: 1;
        max-width: 600px;
        position: relative;
        border-radius: $radius;
        width: calc(100% - 20px);
        background-color: $color-white;
    }
    &__close{
        cursor: pointer;
        width: 30px;
        height: 30px;
        position: absolute;
    right: 5px;
    top: 5px;
    }
    &__inner {
        padding: 20px;
        overflow-y: auto;
        max-height: calc(100vh - 220px);
        &.noSpacing {
            padding: 0;
            overflow: visible;
            max-height: none;
        }
        &.noScroll {
            max-height: none;
            overflow: visible;
        }
    }

    &__header {
        padding: 18px 20px;
        margin-bottom: 10px;
        background-color: $color-gray;
        border-radius: $radius $radius 0 0;
        @include max-screen($mobile) {
            padding: 15px 12px;
        }
        h3 {
            font-weight: 400;
            line-height: 1.15;
            margin-bottom: 0;
            font-size: 20px;
            @include max-screen($mobile) {
                font-size: 18px;
            }
        }

        img {
            width: 16px;
            margin-top: 3px;
            cursor: pointer;
            align-self: flex-start;
        }
    }
    &__footer {
        padding: 0 15px 15px;
        button {
            &+button {
                margin-left: 20px !important;
            }
        }
    }
    &.transitionDisable {
        transition: none;
    }
}