@import "../../assets/scss/variables.scss";

.login {
    padding: 30px;
    @include background-image(login-banner, jpg, center center, no-repeat, cover);
    height: 100vh;
    @include max-screen($tablet) {
        padding: 15px;
    }
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 0;
        background: $gradient;
    }
    &__inner {
        position: relative;
        z-index: 9;
        height: 100%;
    }
    &__logo {
        width: 100%;
    }
    &__form {
        flex: 1;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        @include max-screen($tablet) {
            max-width: 500px;
        }
        h2 {
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 30px;
            color: $color-white;
            @include max-screen($tablet) {
                font-size: 26px;
                margin-bottom: 15px;
            }
        }
    }
    &__formGroup {
        gap: 25px;
        margin-bottom: 35px;
        @include max-screen($tablet) {
            gap: 20px;
            margin-bottom: 25px;
        }
    }
}
