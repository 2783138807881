.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $color-primary;
    padding: 15px 30px;
    height: 60px;
    font-size: 16px;
    color: $color-white;
    border: 0;
    cursor: pointer;
    gap: 7px;
    border-radius: 30px;
    transition: all 0.2s;
    font-weight: 600;
    @include max-screen($tablet) {
        height: 50px;
    }
    &.md{
        padding: 8px 15px ;
        height: 40px;
        font-size: 14px;
    }
    &:hover {
        background-color: darken($color-primary, 3);
        color: $color-white;
    }
    &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }
    &.full{
        width: 100%;
    }
    &.red{
        background-color: $color-error;
        &:hover {
            background-color: darken($color-error, 3);
            color: $color-white;
        }
    }
    &.outline{
        border: 1px solid  $color-primary;
        background-color: $color-white;
        color:  $color-primary;
        &:hover {
            background-color: darken($color-gray-light, 3);
        }
    }
    &__loader {
		width: 40px;
        margin-top: 2px;
		position: relative;
		span {
			width: 6px;
			height: 6px;
			margin-right: 3px;
			border-radius: 50%;
			display: inline-block;
			background: $color-white;
			animation: wave 1.3s linear infinite;
			&:nth-child(2) {
				animation-delay: -1.1s;
			}
			&:nth-child(3) {
				animation-delay: -0.9s;
			}
		}
	}  
    @keyframes wave {
		0%,
		60%,
		100% {
			transform: initial;
		}
		30% {
			transform: translateY(-4px);
		}
	} 

}