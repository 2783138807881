@import '../../assets/scss/variables.scss';

.categories {
    &__header {
        margin-bottom: 20px;

        h1 {
            font-size: 20px;
            width: calc(100% - 250px);

            @include max-screen($mobile) {
                width: calc(100% - 95px);
            }
        }

        span {
            display: none;

            @include max-screen($mobile) {
                height: 40px;
                display: flex;
                border-radius: 20px;
                align-items: center;
                color: $color-white;
                padding: 3px 20px;
                justify-content: center;
                background-color: $color-primary;
            }
        }

        button {
            @include max-screen($mobile) {
                display: none;
            }
        }
    }

    &__count {
        margin-bottom: 10px;
    }

    &__search {
        padding: 20px;
        border-radius: $radius $radius 0 0;
        background-color: $color-white;

        input {
            border: 1px solid $color-gray-dark;
            border-radius: $radius;
            height: 40px;
            width: 100%;
            max-width: 360px;
            padding: 10px 30px 10px 10px;
            background: $color-white-shade;
            @include background-image(search-icon, svg, calc(100% - 10px) center, no-repeat, 12px);


        }
    }
}