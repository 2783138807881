@import "../../assets/scss/variables.scss";

.home{
    &__form{
        margin-top: 30px;
        gap: 50px;
        @include max-screen($tablet) {
            margin-top: 15px;
            gap: 15px;
        }
    }
    &__header{
        gap: 15px;
    }
    &__button{
        gap: 10px;
    }
}