* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $font-primary;
}

body {
    color: $color-black;
    font-size: $font-size-base;
    line-height: $line-height-base;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    &.isHidden{
        overflow: hidden;
        }
}

a {
    outline: none;
    cursor: pointer;
    color: $color-primary;
    text-decoration: none;
    &:hover {
        color: $color-gray;
    }
}

img {
    height: auto;
    display: block;
    max-width: 100%;
}

:focus {
    outline: none;
}

::-ms-expand {
    display: none;
}
input::-ms-reveal,
input::-ms-clear {
   display: none;
}
[role="button"] {
    cursor: pointer;
}

input::-webkit-credentials-auto-fill-button {
    opacity: 0;
}
ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
